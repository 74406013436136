import React from "react";
import {IonBadge, IonCol, IonItem, IonLabel, IonList, IonListHeader, IonRow} from "@ionic/react";
import Order from "./Orders";

const ClosedOrderList: React.FC<{
    order: Order;
}> = props => {

    const closedOrders = props.order.getClosedOrders();

    return (
        <React.Fragment>
            {closedOrders.map((order, i) => {
                return (
                    <IonRow  key={order.id}>
                        <IonCol>
                            <IonList>
                                <IonListHeader>
                                    <h4>Closed Order #{i+1}</h4>
                                </IonListHeader>
                                {Object.values(order.getProducts()).map(product => {
                                    return (
                                        <IonItem lines="full" key={product.id}>
                                            <IonBadge className="ion-margin-end" color="medium">
                                                {product.quantity}x
                                            </IonBadge>
                                            <IonLabel>{product.name}</IonLabel>
                                        </IonItem>
                                    )
                                })}
                            </IonList>
                        </IonCol>
                    </IonRow>
                )

            })}
        </React.Fragment>
    )


}

export default ClosedOrderList;