import firebase from '../firebase_setup';

import {
    ClosedProductsWithQuantityForDatabase,
    Product,
    Products,
    ProductsForDatabaseWithQuantity,
    ProductsForWithQuantity,
    ProductsWithQuantity,
    Table
} from "./tables-context-firebase";
import {ClosedBill} from "./Bill";
import Order, {OrderForDatabase} from "../components/Orders";

class Firestore {
    db: any;

    constructor() {
        this.db = firebase.firestore()
    }

    // @ts-ignore
    public async addTable(tableNumber: number): object {
        const newOrder = new Order();
        const table = {
            tableNumber: tableNumber,
            order: newOrder.parseOrderForDatabase(),
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
            achieved: false,
        };

        return await this.add('tables', table)
    }

    public async deleteTable(tableId: string): Promise<"success" | "error"> {
        try {
            await this.delete('tables', tableId)
            return 'success'
        } catch (e) {
            return 'error'
        }
    }

    public async updateTable(table: Table, order: OrderForDatabase | ClosedProductsWithQuantityForDatabase): Promise<void> {
        const data = {
            tableNumber: table.tableNumber,
            order: order,
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
            createdAt: table.createdAt,
            achieved: table.achieved,
        };
        if (table.paymentType) {
            // @ts-ignore
            data['paymentType'] = table.paymentType
        }
        if (table.tip) {
            // @ts-ignore
            data['tip'] = table.tip
        }
        await this.update('tables', table.id, data)
    }

    public async getTableOrderSave(tableId: string): Promise<ProductsWithQuantity | null> {
        try {
            const result: { order: ProductsWithQuantity } | any = await this.getDocument('tables', tableId);
            if (result.order) {
                return result.order
            }
            return null
        } catch (e) {
            console.log(e)
            return null
        }
    }

    public async addProduct(name: string, price: number, category: "food" | "drinks"): Promise<Product> {
        const data = {
            name,
            price,
            category,
        }
        const result = await this.add('products', data);
        // @ts-ignore
        const documentId = result.id;
        return new Product(documentId, data.name, data.price, data.category)
    }

    public async deleteProduct(productId: string): Promise<"success" | "error"> {
        try {
            await this.delete('products', productId)
            return 'success'
        } catch (e) {
            return 'error'
        }
    }

    // Table operations

    private async add(collectionName: string, object: {}): Promise<string> {
        const docRef = await this.db
            .collection(collectionName)
            .add(object);
        return docRef
    }

    private async  addWithId(collectionName: string, object: {}, documentId: string): Promise<string> {
        return this.db
            .collection(collectionName)
            .doc(documentId)
            .set(object);
    }

    private async getDocument(collectionName: string, docId: string): Promise<any> {
        return this.db.collection(collectionName).doc(docId).get().then((doc: { exists: boolean; data: () => {}; }) => {
            if (!doc.exists) {
                return null
            } else {
                doc.data()
            }
        })
    }

    private async getCollection(collectionName: string): Promise<[]> {
        const collectionArray: any = [];
        await this.db.collection(collectionName).get().then((querySnapshot: { id: any; data: () => any; }[]) => {
            querySnapshot.forEach((doc: { id: any; data: () => any; }) => {
                collectionArray.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
        });
        return collectionArray;
    }

    // Product Operations

    private async delete(collectionName: string, documentId: string): Promise<string> {
        return this.db.collection(collectionName).doc(documentId).delete();
    }

    private async update(collectionName: string, documentId: string, data: {}): Promise<void> {
        return this.db.collection(collectionName).doc(documentId).set(data)
    }

    private parseProductsFromDatabase(
        products: { id: string, name: string, category: 'food' | 'drinks', price: number }[]
    ): Products {
        const parsedProducts = {}

        products.forEach(product => {
            const newProduct = new Product(product.id, product.name, product.price, product.category);
            // @ts-ignore
            parsedProducts[product.id] = newProduct
        })
        return new Products(parsedProducts)
    }

    public async getProducts(): Promise<Products> {
        const products = await this.getCollection('products');
        return this.parseProductsFromDatabase(products);
    }

    // Bill Operations

    public async addClosedBill(
        products: { [productId: string]: ProductsForDatabaseWithQuantity },
        paidAmount: number,
        tip: string,
        id: string,
        closedAt: Date,
        paymentType: 'cash' | 'card',
        tableId: string
    ): Promise<void> {
        const data = {
            id: id,
            products: products,
            paidAmount: paidAmount,
            tip: Number(tip),
            closedAt: firebase.firestore.Timestamp.fromDate(closedAt),
            paymentType: paymentType,
            tableId: tableId,
        };
        this.addWithId('closedBills', data, id);
    }

    public async getClosedBills(): Promise<ClosedBill[]> {
        const closedBills: ClosedBill[] | PromiseLike<ClosedBill[]> = [];
        const billsRef = this.db.collection('closedBills');
        const snapshot = await billsRef.get();
        snapshot.forEach((doc: { data: () => any; id: string; }) => {
            const data = doc.data()
            const newClosedBill = new ClosedBill(data.products, data.paidAmount, data.paymentType, data.tableId);
            newClosedBill.id = doc.id;
            newClosedBill.closedAt = data.closedAt.toDate();
            closedBills.push(newClosedBill);
        });

        return closedBills;
    }

    public async deleteClosedBill(billId: string): Promise<void> {
         await this.delete('closedBills', billId)
}


}

export default Firestore;