import {ValidationErrors, Values} from './useFormValidation';


export default function validateCreateTable(values: Values) {
    let errors: ValidationErrors = {}

    // Table Errors
    if (!values.tableNumber) {
        errors.tableNumber = "Table number required"
    }

    return errors;
};