import React, {useContext} from 'react';
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import FirebaseContext from "../../data/tables-context-firebase";
import {useHistory} from "react-router";
// stylesheet
import './profile.css';

const Profile: React.FC = () => {
    const {user, logout, bills} = useContext(FirebaseContext);
    const history = useHistory();

    const filteredBills = bills?.filter(bill => new Date(bill.closedAt).getDate() === new Date().getDate())
    const totalSalesOfToday = filteredBills?.reduce((accumulator, current) => accumulator += parseFloat(current.getTotalPrice()), 0);
    const totalTipOfToday = filteredBills?.reduce((accumulator, current) => accumulator += parseFloat(current.tip), 0);

    let totalTipPercentage;

    if (totalSalesOfToday && totalTipOfToday) {
        totalTipPercentage = totalTipOfToday! / totalSalesOfToday! * 100
        totalTipPercentage.toFixed(2);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Profile</IonTitle>
                    <IonButtons slot="end">
                        <IonButton fill="clear" onClick={() => {
                            logout()
                            history.replace('/login')
                            console.log(history)
                        }}>Log out</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <h1>Hey, {user?.displayName ? user.displayName : "there"}</h1>
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonGrid className="ion-text-center">
                            <IonRow>
                                <IonCol>
                                    <IonCardSubtitle>Today's Sales</IonCardSubtitle>
                                </IonCol>
                                <IonCol>
                                    <IonCardSubtitle>Today's Tip</IonCardSubtitle>
                                    <IonCardSubtitle>({totalTipPercentage?.toFixed(2)}%)</IonCardSubtitle>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonBadge color="light"
                                              className="custom-badge">{totalSalesOfToday?.toFixed(2)}€</IonBadge>
                                </IonCol>
                                <IonCol>
                                    <IonBadge color="success"
                                              className="custom-badge">{totalTipOfToday?.toFixed(2)}€</IonBadge>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default Profile;