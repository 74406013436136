import React, {useContext, useState} from 'react';
import {
    IonBadge,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonText
} from "@ionic/react";
import useFormValidation from "../../../components/auth/useFormValidation";
import validateLogin from "../../../components/auth/validateLogin";
import FirebaseContext from "../../../data/tables-context-firebase";

import {useHistory} from "react-router";

const INITIAL_STATE = {
    name: '',
    email: 'test@taminobaumann.com',
    password: 'demouser',
}


const Login: React.FC = props => {
    const firebaseCtx = useContext(FirebaseContext)
    const {
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, validateLogin, authenticateUser)

    const history = useHistory()

    const [login, setLogin] = useState(true);
    const [firebaseError, setFirebaseError] = useState('');

    async function authenticateUser() {
        const {name, password, email} = values;
        try {
            login
                ? await firebaseCtx.login(email!, password!)
                : await firebaseCtx.register(email!, password!, name)
            values.name = "";
            values.password = "";
            values.email = "";
            history.push('/tables');
            window.location.reload();
        } catch (errors) {
            setFirebaseError(errors.message);
        }

    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center ion-margin-bottom">
                            <h1>Welcome Back!</h1>
                            <IonBadge>
                                <h2>HINT</h2>
                                <p>You won't be able to make any changes to the data.</p>
                                <p>Email: test@taminobaumann.com</p>
                                <p>Password: demouser</p>
                            </IonBadge>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            {!login && (
                                <IonItem lines="full" className="ion-margin-bottom">
                                    <IonLabel position="floating">Name</IonLabel>
                                    <IonInput
                                        type="text"
                                        onIonChange={handleChange}
                                        name="name"
                                        value={values.name}
                                    />
                                </IonItem>
                            )}
                            <IonItem lines="full">
                                <IonLabel position="floating">Email</IonLabel>
                                <IonInput
                                    type="email"
                                    onIonChange={handleChange}
                                    name="email"
                                    value={values.email}
                                    color={errors.email && "danger"}
                                />
                            </IonItem>
                            {errors.email && <IonText color="danger"><p>{errors.email}</p></IonText>}
                            <IonItem lines="full" className="ion-margin-top">
                                <IonLabel position="floating">Password</IonLabel>
                                <IonInput
                                    type="password"
                                    onIonChange={handleChange}
                                    onIonBlur={handleBlur}
                                    name="password"
                                    value={values.password}
                                    color={errors.password && "danger"}
                                />
                            </IonItem>
                            {errors.password && <IonText color="danger"><p>{errors.password}</p></IonText>}
                            {firebaseError && <IonText color="danger"><p>{firebaseError}</p></IonText>}
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonCol size="10" className="ion-margin-top">
                            <IonButton expand="block" onClick={handleSubmit}
                                       disabled={isSubmitting}>{login ? "Log in" : "Sign up"}</IonButton>
                            {/*{login ? (*/}
                            {/*        <p>Already have an account? <IonButton fill="clear" onClick={() => setLogin(prevState => !prevState)}*/}
                            {/*                                       style={{textDecoration: "none"}}>*/}
                            {/*            Register.*/}
                            {/*        </IonButton>*/}
                            {/*        </p>*/}
                            {/*    ) :*/}
                            {/*    (*/}
                            {/*        <p>Already have an account? <IonButton fill="clear" onClick={() => setLogin(prevState => !prevState)}*/}
                            {/*                                       style={{textDecoration: "none"}}>Log*/}
                            {/*            in.</IonButton></p>*/}
                            {/*    )}*/}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
};

export default Login;