import React from 'react';
import {
    IonBadge,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonTitle
} from "@ionic/react";
import {Table} from "../../data/tables-context-firebase";

const TableList: React.FC<{ tables: any, tablesAreOpen: boolean }> = props => {
    if (props.tables.length !== 0) {
        return (
            props
                .tables
                .map((table: Table) => {
                    return (
                        <IonCol size="6" key={table.id}>
                            <IonCard className="ion-text-center" routerLink={"tables/" + table.id}>
                                <IonCardHeader>
                                    <IonCardTitle>T{table.tableNumber}</IonCardTitle>
                                    {
                                        props.tablesAreOpen
                                            ?
                                            <IonCardSubtitle>
                                                {table.createdAt.toLocaleTimeString('en-UK',
                                                    {
                                                        hour12: false,
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    })}
                                            </IonCardSubtitle>
                                            :
                                            <IonCardSubtitle>{table.createdAt.toLocaleDateString('de-DE')}</IonCardSubtitle>
                                    }
                                </IonCardHeader>
                                <IonCardContent>
                                    {
                                        props.tablesAreOpen
                                            ?
                                            Number(table.order.getOpenProductsTotalPrice()) > 0
                                                ?
                                                <IonBadge color="success">
                                                    {table.order.getOpenProductsTotalPrice()}€
                                                </IonBadge>
                                                :
                                                <IonBadge color="light">0€</IonBadge>
                                            :
                                            table.order.getClosedOrderTotalTip() && parseFloat(table.order.getClosedOrderTotalTip()) > 0
                                                ?
                                                <IonBadge color="success">
                                                    {table.order.getClosedOrderTotalTip()}€
                                                </IonBadge>
                                                :
                                                <IonBadge color="light">no tip</IonBadge>

                                    }

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    );
                })
        )
    }
    return <IonCol
        className="ion-margin-top"><IonTitle>{props.tablesAreOpen ? " No open tables" : "No achieved table"}</IonTitle></IonCol>
};

export default TableList;