import React, {useContext} from 'react';
import {Route, Redirect } from 'react-router-dom';
import tablesContext from "../data/tables-context-firebase";

// @ts-ignore
const PrivateRoute = ({component: RouteComponent, ...rest }) => {
    const {user} = useContext(tablesContext);
    return (
        <Route
            {...rest}
            render={routeProps => !!user ? (<RouteComponent {...routeProps} />
            ): (
                <Redirect to={"/login"} />
            )
            }
            />
    );
};

export default PrivateRoute;