import {
    ProductsForDatabaseWithQuantity,
    ProductsForWithQuantity,
    ProductsWithQuantity,
    ProductWithQuantity
} from "../data/tables-context-firebase";
import {ClosedBillForDatabase} from "../data/Bill";

const uniqid = require('uniqid');

interface Orders {
    [id: string]: ProductsWithQuantity
}

export interface OrderForDatabase {
    orders: {
        [orderId: string]: {
            id: string,
            isClosed: boolean,
            products: { [productId: string]: ProductsForDatabaseWithQuantity }
            bill: null | ClosedBillForDatabase
        }
    }
}

class Order {
    orders: Orders;

    constructor(initialOrderId?: string, initialOrder?: ProductsWithQuantity) {
        this.orders = this.initialiseFirstOrder(initialOrderId, initialOrder);
    }

    public getOpenOrders(): ProductsWithQuantity[] {
        return Object.values(this.orders).filter(order => !order.isClosed);
    }

    public getClosedOrders(): ProductsWithQuantity[] {
        return Object.values(this.orders).filter(order => order.isClosed);
    }

    public getFirstOpenOrder(): ProductsWithQuantity {
        return this.getOpenOrders().filter(order => order.id.endsWith('first'))[0];
    }

    public getOpenProducts(): ProductsWithQuantity {
        const openOrders = this.getOpenOrders();
        let finalProductsObject: ProductsForWithQuantity = {};
        for (let i = 0; i < openOrders.length; i++) {
            const productsObject = openOrders[i].getProductsSave();
            if (productsObject) {
                finalProductsObject = {...finalProductsObject, ...productsObject};
            }
        }
        return new ProductsWithQuantity(finalProductsObject);
    }

    public getOpenProductsTotalPrice(): string {
        return this.getOpenProducts().generateBill().getTotalPrice();
    }

    public getOpenProductsTotalWithTip(): string {
        return this.getOpenProducts().generateBill().getTotalPriceWithTip();
    }

    public parseOrderForDatabase(): OrderForDatabase {
        const orderForDatabase = {orders: {}};
        Object.values(this.orders).forEach(order => {
            // @ts-ignore
            orderForDatabase.orders[order.id] = order.getOrderForDatabase();
        })
        return orderForDatabase;
    }

    public addOrder(order: ProductsWithQuantity) {
        const id = order.id;
        this.orders[id] = order;
    }

    public addOrderFromDatabase(productsWithQuantity: ProductsWithQuantity) {
        this.orders[productsWithQuantity.id] = productsWithQuantity;
    }

    public updateOrderSave(orderId: string, order: ProductsWithQuantity) {
        if (!this.hasOrder(orderId)) {
            return null;
        } else {
            this.orders[orderId] = order;
        }
    }

    public splitFirstOrder(productsWithQuantityToRemove: { [productId: string]: ProductWithQuantity }): { closeTable: boolean, newOrder: ProductsWithQuantity } {
        const newOrder = new ProductsWithQuantity({});
        const firstOrder = this.getFirstOpenOrder();
        let closeTable = false;
        Object.values(productsWithQuantityToRemove).forEach(product => {
            firstOrder.decreaseQuantityFromProduct(product);
            newOrder.addProductSave(product.id, product.name, product.price, product.category, product.quantity);
        })
        this.addOrder(newOrder);
        if (Object.values(firstOrder.getProducts()).length === 0) {
            firstOrder.isClosed = false;
            closeTable = true;
        }
        return {closeTable, newOrder,}
    }

    public getClosedOrderTotalTip(): string {
        const closedOrders = this.getClosedOrders()
        if (closedOrders.length === 0) {
            return "0";
        }
        const totalTip = closedOrders.reduce((accumulator, current) => {
            return accumulator + +current.getClosedBillSave()!.tip
        }, 0);
        return totalTip.toFixed(2);
    }

    public getClosedOrderTotalAmount(): string {
        const closedOrders = this.getClosedOrders();
        if (closedOrders.length === 0) {
            return '0';
        }
        const totalAmount = closedOrders.reduce((accumulator, current) => {
            return accumulator + +current.generateBill().getTotalPrice();
        }, 0)

        return totalAmount.toFixed(2);
    }

    public getClosedBillIds(): string[] {
        const closedOrders = this.getClosedOrders();
        let billIdArray: string[] = [];
        if (closedOrders.length === 0) {
            return billIdArray;
        }
        closedOrders.forEach(order => {
            billIdArray.push(order.getClosedBillSave()!.id);
        })
        return billIdArray
    }

    private hasOrder(orderId: string) {
        const keyList = Object.values(this);
        return orderId in keyList;
    }

    private getOrderSave(orderId: string) {
        if (!this.hasOrder(orderId)) {
            return null;
        } else {
            return this.orders[orderId];
        }
    }

    private initialiseFirstOrder(orderId?: string, productsWithQuantity?: ProductsWithQuantity): Orders {
        if (orderId && productsWithQuantity) {
            return {[orderId]: productsWithQuantity};
        }
        let newOrderId = uniqid() + '-first';
        const newOrder = new ProductsWithQuantity({});
        newOrder.id = newOrderId;
        return {[newOrderId]: newOrder};
    }
}

export default Order;