import React from 'react';
import {IonButton, IonChip, IonIcon, IonItem, IonLabel, IonList, IonText} from "@ionic/react";
import {addOutline, removeOutline} from "ionicons/icons";
import {ProductInOrder} from "../data/tables-context-firebase";

const AddOrderProductList: React.FC<{
    products: ProductInOrder[],
    searchedText: string,
    changeOrderQuantity: (productId: string, increase?: boolean, decrease?: boolean) => void | undefined
}> = props => {

    const decreaseHandler = (productId: string, event: any) => {
        event.stopPropagation()
        props.changeOrderQuantity(productId, false, true)
    }
    const increaseHandler = (productId: string, event: any) => {
        event.stopPropagation()
        props.changeOrderQuantity(productId, true, false)
    }

    return (
        <IonList
            inset={true}
            lines="inset"
        >
            {props.products.map(product => {
                return (
                    <IonItem key={product.id}>
                        <IonChip slot="start" color={product.quantity > 0 ? "success" : ""}>{product.quantity}</IonChip>
                        <IonLabel>{product.name} : <IonText color="medium">{product.price}€</IonText></IonLabel>
                         <IonButton fill="clear" onClick={e => decreaseHandler(product.id, e)}>
                             <IonIcon slot="icon-only" icon={removeOutline} color="secondary" hidden={product.quantity === 0}/>
                         </IonButton>
                                <IonButton fill="clear" onClick={e => increaseHandler(product.id, e)}>
                                    <IonIcon slot="icon-only" icon={addOutline} color="secondary"/>
                                </IonButton>
                    </IonItem>
                )
            })}
        </IonList>
    )
};

export default AddOrderProductList;