import React from "react";
import {IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from "@ionic/react";
import {Redirect, useHistory} from "react-router";
import PrivateRoute from "../PrivateRoute";
import TablesOpen from "../../sides/Tables/TablesOpen";
import Table from "../../sides/Table/Table";
import Profile from "../../sides/Profile/Profile";
import {fileTrayFullOutline, layersOutline, listOutline, personOutline, reorderFourOutline} from "ionicons/icons";
import Products from "../../sides/Products";
import TablesAchieve from "../../sides/Tables/TablesAchieve";

const Tabs: React.FC = () => {
    return (
        <IonTabs>
            <IonRouterOutlet>
                <PrivateRoute path="/tables" component={TablesOpen} exact/>
                <PrivateRoute path={"/tables/:tableId"} component={Table} exact/>
                <PrivateRoute path={"/profile"} component={Profile} exaxt/>
                <PrivateRoute path={"/products"} component={Products} exact />
                <PrivateRoute path={"/achieve"} component={TablesAchieve} exact />
                <Redirect to={"/tables"} />
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
                <IonTabButton tab="tables" href="/tables">
                    <IonIcon icon={layersOutline}/>
                    <IonLabel>Tables</IonLabel>
                </IonTabButton>
                <IonTabButton tab="products" href="/products">
                    <IonIcon icon={listOutline}/>
                    <IonLabel>Products</IonLabel>
                </IonTabButton>
                <IonTabButton tab="achieve" href="/achieve">
                    <IonIcon icon={fileTrayFullOutline}/>
                    <IonLabel>Achieve</IonLabel>
                </IonTabButton>
                <IonTabButton tab="profile" href="/profile">
                    <IonIcon icon={personOutline}/>
                    <IonLabel>Me</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    )
};

export default Tabs;