import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import FirebaseContextProvider from "./data/FirebaseContextProvider";

ReactDOM.render(
    <FirebaseContextProvider>
        <App/>
    </FirebaseContextProvider>,
    document.getElementById('root'));

