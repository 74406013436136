import {ValidationErrors, Values} from './useFormValidation';


export default function validateCreateDivers(values: Values) {
    let errors: ValidationErrors = {}

    // Product Errors
    if (!values.name) {
        errors.tableNumber = "Product name is required"
    } else if (values.price! <= 0){
        errors.price = "The price can not be zero or a negative number"
    }

    return errors;
};