import React from "react";
import {IonCol, IonContent, IonGrid, IonPage, IonRow, IonSpinner, IonText} from "@ionic/react";

const StartupLoading: React.FC = () => {
    return (
        <IonPage>
            <IonContent className="ion-align-items-center ion-text-center">
                <IonGrid style={{marginTop: "70%"}}>
                    <IonRow>
                        <IonCol>
                             <IonSpinner name="crescent" />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                             <IonText>Loading</IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>


            </IonContent>
        </IonPage>
    )
}

export default StartupLoading;