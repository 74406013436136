import React, {useContext, useEffect, useState} from 'react';
import {
    IonAlert,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon, IonLoading,
    IonPage,
    IonSearchbar, IonSpinner,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {addOutline} from "ionicons/icons";
import FirebaseContext from "../data/tables-context-firebase";
import AddProductModal from "../components/AddProductModal";
import ProductList from "../components/ProductList";
import Firestore from "../data/Database";

const Products: React.FC = () => {
    const db = new Firestore();
    const firebaseCtx = useContext(FirebaseContext)
    const originalProductArray = firebaseCtx.products

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [products, setProducts] = useState(originalProductArray);
    const [loading, setLoading] = useState(false);
    const [itemToDelete, setItemToDelete] = useState('');


    useEffect(() => {
        const filterProducts = originalProductArray!.searchProducts(searchText);
        setProducts(filterProducts)
    }, [searchText]);

    const getLatestProducts = async () => {
        setSearchText('');
        setProducts(originalProductArray);
    }

    const startEditingHandler = () => {
        setShowModal(true);
    };

    const cancelHandler = async () => {
        const latestProducts = await db.getProducts()
        setProducts(latestProducts)
        setShowModal(false);
    };

    const setItemToDeleteHandler = (productId: string) => {
      setShowAlert(true);
      setItemToDelete(productId);
    };

    const itemDeleteHandler = async () => {
        if (!itemToDelete){
            return;
        }
        const itemId = itemToDelete;
        db.deleteProduct(itemId).then(result => {
            if (result === 'success'){
                db.getProducts().then(products => {
                    setProducts(products)
                })
                firebaseCtx.getProducts();
            }
        })
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Products</IonTitle>
                    <IonButtons slot="end">
                        <IonButton fill="clear" onClick={startEditingHandler}>
                            <IonIcon slot="icon-only" icon={addOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar
                    showCancelButton="focus"
                    inputMode="search"
                    value={searchText}
                    onIonChange={e => setSearchText(e.detail.value!)}
                    placeholder={"Find product..."}
                    onIonClear={() => {
                        setLoading(true);
                        setTimeout(() => {
                            setLoading(false);
                        }, 20)
                        getLatestProducts()
                    }}
                    onIonCancel={getLatestProducts}
                    autocomplete={"off"}
                />
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-text-center">
                {!loading && products && <ProductList products={products} searchedText={searchText} setDeleteItem={setItemToDeleteHandler}/>}
                {loading && <IonSpinner name="crescent" className="ion-margin-top"/>}
                <AddProductModal show={showModal} onCancel={cancelHandler}/>
                <IonAlert
                    isOpen={showAlert}
                    header={'Delete Product'}
                    message={'Are you sure?'}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                setItemToDelete('');
                                setShowAlert(false);
                            }
                        },
                        {
                            text: 'Delete',
                            handler: () => {
                                itemDeleteHandler();
                            }
                        }
                    ]}
                />
            </IonContent>
        </IonPage>
    );
}

export default Products;