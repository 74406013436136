import React, {useContext, useState} from 'react';
import {
    IonAlert,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent, IonFooter,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader, IonLoading,
    IonModal,
    IonRow,
    IonText,
    IonToolbar
} from "@ionic/react";
import FirebaseContext, {ProductsWithQuantity, Table} from "../data/tables-context-firebase";

import './CloseModal.css';
import Firestore from "../data/Database";
import {Bill} from "../data/Bill";
import {uid} from "../data/FirebaseContextProvider";


const CloseModal: React.FC<
    {
        show: boolean;
        table: Table;
        onCancel: () => void;
        order: ProductsWithQuantity;
        isSplitting: boolean;
        splitOrder: ProductsWithQuantity;

    }
    > = props => {
    const db = new Firestore();
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const cancelHandler = () => {
        props.onCancel()
    };


    const completeHandler = () => {
        setShowAlert(true);
    };

    const firebaseCtx = useContext(FirebaseContext);

    const paymentHandler = async (e: {finalAmount: number}, paymentType: 'card' | 'cash') => {
        setShowAlert(false);
        setLoading(true);
        let finalPayment = e.finalAmount;
        if (finalPayment.toString().trim().length === 0) {
            finalPayment = +props.order.generateBill().getTotalPrice();
        }
        const {closeTable, newOrder} = props.table.order.splitFirstOrder(props.order.getProducts());
        newOrder.closeProductsWithQuantity(finalPayment, paymentType, props.table.id)
        if (closeTable){
            props.table.achieved = true;
        }
        await db.updateTable(props.table, props.table.order.parseOrderForDatabase());
        setLoading(false);
        props.onCancel();
    };

    return (
        <IonModal
            isOpen={props.show}
        >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={cancelHandler} color="medium" >
                            <IonLabel>Cancel</IonLabel>
                        </IonButton>
                    </IonButtons>
                    {firebaseCtx.user?.uid === uid && <IonButtons slot="end">
                        <IonButton onClick={completeHandler} color="medium">
                            <IonLabel>Complete</IonLabel>
                        </IonButton>
                    </IonButtons>}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard className="ion-no-padding">
                    <IonCardHeader>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonCardTitle>Bill</IonCardTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-no-margin">
                                <IonCol>
                                    <IonCardSubtitle className="ion-no-margin">
                                        <strong>Table {props.table.tableNumber}</strong> | {props.table.createdAt.toLocaleTimeString('en-UK', {
                                        hour12: false,
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })} - {new Date().toLocaleTimeString('en-UK', {
                                        hour12: false,
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}</IonCardSubtitle>
                                </IonCol>
                            </IonRow>

                        </IonGrid>
                    </IonCardHeader>
                    { props.order.generateBill().getProductsSave() !== null && <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <h1>Order</h1>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonList>
                                        {props.order.generateBill().getDrinks().length > 0 && (
                                            <IonListHeader>Drinks</IonListHeader>
                                        )}
                                        {props.order.generateBill().getDrinks().length > 0 &&
                                        props.order.generateBill().getDrinks().map(product => {
                                            return (
                                                <IonItem lines="full" key={product.id}>
                                                    <IonBadge className="ion-margin-end"
                                                              color="medium"
                                                              id="quantity-badge">{product.quantity}x</IonBadge>
                                                    <p>{product.name} <IonText
                                                        color="medium">@{product.getPrice()}€</IonText></p>
                                                    <p slot={"end"}>{product.getTotalPrice()}€</p>
                                                </IonItem>
                                            )
                                        })}
                                    </IonList>
                                    <IonList>
                                        {props.order.generateBill().getFood().length > 0 && (
                                            <IonListHeader>Food</IonListHeader>
                                        )}
                                        {props.order.generateBill().getFood().length > 0 &&
                                        props.order.generateBill().getFood().map(product => {
                                            return (
                                                <IonItem lines="full" key={product.id}>
                                                    <IonBadge className="ion-margin-end"
                                                              color="medium"
                                                              id="quantity-badge">{product.quantity}x</IonBadge>
                                                    <p>{product.name} <IonText
                                                        color="medium">@{product.getPrice()}€</IonText></p>
                                                    <p slot={"end"}>{product.getTotalPrice()}€</p>
                                                </IonItem>
                                            )
                                        })}
                                    </IonList>
                                </IonCol>
                            </IonRow>
                            {props.order.generateBill().getProductsSave()!.length <= 7 && (
                                <React.Fragment>
                                    <IonRow className="ion-margin-top">
                                        <IonCol className="ion-text-left">
                                            <IonCardTitle>Total:</IonCardTitle>
                                        </IonCol>
                                        <IonCol className="ion-text-right">
                                            <IonCardTitle>{props.order.generateBill().getTotalPrice()}€</IonCardTitle>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="ion-text-right">
                                            <IonCardSubtitle>with 10%
                                                tip: {props.order.generateBill().getTotalPriceWithTip()} €</IonCardSubtitle>
                                        </IonCol>
                                    </IonRow>
                                </React.Fragment>
                            )}
                        </IonGrid>
                    </IonCardContent> }
                </IonCard>
                <IonAlert
                    isOpen={showAlert}
                    header={"Paid amount?"}
                    buttons={[
                        {
                            text: "card",
                            handler: (e) => {
                                paymentHandler(e,  'card');
                            }
                        },
                        {
                            text: "cash",
                            handler: (e) => {
                                paymentHandler(e, 'cash');
                            }
                        }
                    ]}
                    inputs={[
                        {
                            name: "finalAmount",
                            type: "number",
                            id: "final-amount",
                        }
                    ]}
                />
                <IonLoading
                    isOpen={loading}
                    message={"Closing table..."}
                />
            </IonContent>
            {Object.keys(props.table.order.getOpenProducts().getProducts()).length > 7 && <IonFooter>
                <IonGrid>
                    <IonRow className="ion-margin-top">
                        <IonCol className="ion-text-left">
                            <IonCardTitle>Total:</IonCardTitle>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <IonCardTitle>{props.order.generateBill().getTotalPrice()}€</IonCardTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-right">
                            <IonCardSubtitle>mit 10%
                                Trinkgeld: {props.order.generateBill().getTotalPriceWithTip()} €</IonCardSubtitle>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>}
        </IonModal>
    )
}

export default CloseModal;