import {ValidationErrors, Values} from './useFormValidation';


export default function validateCreateProduct(values: Values) {
    let errors: ValidationErrors = {}

    // Product Errors
    if (!values.name) {
        errors.tableNumber = "Product name is required"
    } else if (!values.category){
        errors.category = "Category is required"
    } else if (!values.price){
        errors.price = "Price is required"
    } else if (values.category !== "drinks" && values.category !== "food"){
        errors.category = "Category can only be drinks or food"
    } else if (values.price <= 0){
        errors.price = "The price can not be zero or a negative number"
    }

    return errors;
};