import React, {useContext, useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {useHistory} from "react-router-dom";

import './EditTablesModel.css';
import FirebaseContext from "../data/tables-context-firebase";
import useFormValidation from "./auth/useFormValidation";
import validateCreateTable from "./auth/validateCreateTable";

import Firestore from "../data/Database";
import AlertTableDoesExist from "../sides/Tables/TableDoesExistAlert";
import {uid} from "../data/FirebaseContextProvider";

const INITIAL_STATE = {
    tableNumber: 0,
}


const TableModal: React.FC<{ show: boolean; onCancel: () => void }> = props => {
    const db = new Firestore();
    const {tables, user} = useContext(FirebaseContext);
    const {handleSubmit, handleChange, values, errors} = useFormValidation(INITIAL_STATE, validateCreateTable, handleCreateTable)

    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
     const firebaseCtx = useContext(FirebaseContext);

    const history = useHistory();

    useEffect(() => {
        setLoading(false);
    }, [])

    async function handleCreateTable() {
        if (user) {
            const {tableNumber} = values;
            const userId = user.uid;
            try {
                // @ts-ignore
                const {id} = await db.addTable(tableNumber!);
                history.push("/tables/" + id);
                props.onCancel()
            } catch (e) {
                console.log(e)
                console.log('error')
            }


        }
    }

    const handleClick = (event: any) => {
        setLoading(true);
        const tableNumberExists = !!tables.filter(table => !table.achieved)
            .find(table => table.tableNumber === values.tableNumber!)
        if (tableNumberExists) {
            setLoading(false);
            setOpenAlert(true);
            return;
        }
        handleSubmit(event)
        setLoading(false);
    }


    const handleCancel = () => {
        setLoading(false);
        props.onCancel();
    };

    return (
        <IonModal
            isOpen={props.show}
        >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Add Table
                    </IonTitle>
                    <IonButtons slot="start">
                        <IonButton onClick={handleCancel}>
                            <IonLabel>Cancel</IonLabel>
                        </IonButton>
                    </IonButtons>
                    {firebaseCtx.user?.uid === uid && <IonButtons slot="end">
                        <IonButton onClick={handleClick}>
                            <IonLabel>Done</IonLabel>
                        </IonButton>
                    </IonButtons>}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center ion-margin-top">
                            <IonItem>
                                <IonLabel>Table Number:</IonLabel>
                                <IonInput
                                    type="number"
                                    name="tableNumber"
                                    onIonChange={handleChange}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    {errors.tableNumber && (
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonText color="danger">{errors.tableNumber}</IonText>
                            </IonCol>
                        </IonRow>
                    )}
                </IonGrid>
                <AlertTableDoesExist
                    openAlert={openAlert}
                    setOpenAlert={setOpenAlert}
                    tables={tables}
                    history={history}
                    onCancel={props.onCancel}
                    values={values}
                />
                <IonLoading
                    isOpen={loading}
                    message={"Creating table..."}
                />
            </IonContent>
        </IonModal>
    );
};

export default TableModal;