import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLoading,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToast,
    IonToolbar
} from "@ionic/react";
import {useHistory, useParams} from "react-router";
import {addOutline, closeOutline, listOutline} from "ionicons/icons";
import AddOrderModal from "../../components/AddOrderModal";

import './table.css';
import FirebaseContext, {ProductsWithQuantity} from "../../data/tables-context-firebase";

import Firestore from "../../data/Database";
import CloseModal from "../../components/CloseModal";
import OrderList from "../../components/OrderList";
import ClosedOrderList from "../../components/ClosedOrderList";
import AddDiversModal from "../../components/AddDiversModal";
import {uid} from "../../data/FirebaseContextProvider";

const Table: React.FC = () => {
    const db = new Firestore();


    const [startEditing, setStartEditing] = useState(false);
    const [startAddingOrder, setStartAddingOrder] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [order, setOrder] = useState<ProductsWithQuantity>(new ProductsWithQuantity({}));
    const [startClosingTable, setStartClosingTable] = useState(false);
    const [isSplitting, setIsSplitting] = useState<boolean>(false);
    const [splitOrder, setSplitOrder] = useState(new ProductsWithQuantity({}));
    const [copiedOrder, setCopiedOrder] = useState(new ProductsWithQuantity({}));
    const [splitBillTotal, setSplitBillTotal] = useState<number>(0);
    const [startAddingDivers, setStartAddingDivers] = useState<boolean>(false);


    const tableNumberInputRef = useRef<HTMLIonInputElement>(null);

    const firebaseCtx = useContext(FirebaseContext);

    const selectedTableId = useParams<{ tableId: string }>().tableId;
    const selectedTable = firebaseCtx.tables.find(table => table.id === selectedTableId);


    const history = useHistory();

    const addOrderHandler = () => {
        setStartAddingOrder(true)
    };

    const addDiversHandler = () => {
        setStartAddingDivers(true);
    };

    const cancelModalHandler = () => {
        setStartAddingOrder(false);
    }

    const cancelAddDiversModal = () => {
        setStartAddingDivers(false);
    }

    const editTableHandler = () => {
        setStartEditing(true);
    };

    const cancelClosingTable = () => {
        setIsSplitting(false);
        setStartClosingTable(false);
    };

    const finishTableEditHandler = async () => {
        setErrorMessage('');
        const tableNumberInput = tableNumberInputRef.current!.value;
        if (!tableNumberInput || tableNumberInput.toString().trim().length === 0) {
            setErrorMessage('Please provide a valid input: T followed by a positive number.')
            return;
        }
        const newTableNumber = tableNumberInput.toString().split('T')[1];
        const tableNumberInt = parseInt(newTableNumber)
        if (!Number.isInteger(tableNumberInt)) {
            setErrorMessage('Please provide a valid input: T followed by a positive number.')
            return;
        }
        if (tableNumberInt === selectedTable!.tableNumber) {
            setStartEditing(false);
            return;
        }
        if (!!firebaseCtx.tables.find(table => table.tableNumber === tableNumberInt)) {
            setErrorMessage('This table already exists, please choose another one.')
            return;
        }
        setShowLoading(true)
        await firebaseCtx.updateTableNumber(selectedTableId, tableNumberInt)
        setStartEditing(false);
        setShowLoading(false);
        setErrorMessage('');
    };

    const deleteTableHandler = async () => {
        const closedBillIds = selectedTable!.order.getClosedBillIds();
        for (const billId of closedBillIds) {
            await db.deleteClosedBill(billId);
        }
        db.deleteTable(selectedTableId).then(result => {
            if (result === 'success') {
                setStartEditing(false);
                setOpenAlert(false);
                setOrder(new ProductsWithQuantity({}));
            }
        });
    };

    const splitButtonHandler = () => {
        setIsSplitting(prevState => !prevState)
        if (!isSplitting) {
            setSplitOrder(new ProductsWithQuantity({}));
            setSplitBillTotal(+splitOrder.generateBill().getTotalPrice());
        }
    };

    const getDeepCopyOfProductsWithQuantity = () => {
        const newProductsWithQuantity = new ProductsWithQuantity({});
        const products = order.getProducts();
        Object.values(products).forEach(product => {
            newProductsWithQuantity.addProductSave(product.id, product.name, product.price, product.category, product.quantity);
        })
        return newProductsWithQuantity;
    };

    useEffect(() => {
        setOrder(selectedTable!.order.getFirstOpenOrder());
    }, [])


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Table {selectedTable?.tableNumber}</IonTitle>
                    <IonButtons>
                        <IonBackButton defaultHref="/tables"/>
                    </IonButtons>

                    {!isSplitting && firebaseCtx.user?.uid === uid && <IonButtons slot="end">
                        {
                            startEditing ? (
                                <IonButton onClick={finishTableEditHandler}>Done</IonButton>
                            ) : (
                                <IonButton onClick={editTableHandler}>Edit</IonButton>
                            )
                        }
                    </IonButtons>}
                </IonToolbar>
            </IonHeader>
            {selectedTable && <IonContent>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <IonCard>
                                <IonCardHeader>
                                    {selectedTable.achieved && <IonBadge color="danger">Closed</IonBadge>}
                                    {
                                        startEditing ?
                                            <IonItem className="ion-text-center">
                                                <IonInput id="table-input-edit" color="primary"
                                                          ref={tableNumberInputRef}
                                                          value={'T' + selectedTable?.tableNumber}
                                                />
                                            </IonItem>
                                            :
                                            <IonCardTitle color="primary"><h1>T {selectedTable?.tableNumber}</h1>
                                            </IonCardTitle>
                                    }
                                    {errorMessage && <IonText color="danger"><p>{errorMessage}</p></IonText>}
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonGrid className="ion-no-margin ion-no-padding">
                                        {selectedTable.achieved && <IonRow className="ion-margin-bottom">
                                            <IonCol>
                                                <IonText>{selectedTable.createdAt.toLocaleTimeString('en-UK', {
                                                    hour12: false,
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })} </IonText> -
                                                <IonText> {selectedTable.updatedAt.toLocaleTimeString('en-UK', {
                                                    hour12: false,
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}</IonText>
                                            </IonCol>
                                        </IonRow>}
                                        {!isSplitting &&
                                        <IonRow>
                                            <IonCol>
                                                {!selectedTable.achieved &&
                                                <IonText><p
                                                    id="sum">{selectedTable?.order.getOpenProductsTotalPrice()}€</p>
                                                </IonText>
                                                }
                                                {selectedTable.achieved &&
                                                <IonText><p
                                                    id="sum">{selectedTable?.order.getClosedOrderTotalAmount()}€</p>
                                                </IonText>
                                                }
                                            </IonCol>
                                        </IonRow>
                                        }
                                        {selectedTable.achieved && <IonRow className="ion-margin-top">
                                            <IonCol>
                                                <IonBadge color="success">
                                                    <p>Tip: {selectedTable.order.getClosedOrderTotalTip()}€</p>
                                                </IonBadge>
                                            </IonCol>
                                        </IonRow>}
                                        {
                                            !startEditing
                                            &&
                                            (<React.Fragment>
                                                {
                                                    !selectedTable.achieved
                                                    &&
                                                    <React.Fragment>
                                                        <IonRow className="ion-margin-top">
                                                            <IonCol>
                                                                <IonButton id="add-button" color="tertiary" size="small"
                                                                           onClick={splitButtonHandler}
                                                                >
                                                                    <IonIcon slot="icon-only" icon={listOutline}/>
                                                                </IonButton>
                                                            </IonCol>
                                                            {!isSplitting ?
                                                                (
                                                                    <React.Fragment>
                                                                        <IonCol>
                                                                            <IonButton id="add-button" color="success"
                                                                                       size="small"
                                                                                       onClick={addOrderHandler}>
                                                                                <IonIcon slot="icon-only"
                                                                                         icon={addOutline}/>
                                                                            </IonButton>
                                                                        </IonCol>
                                                                        <IonCol>
                                                                            <IonButton id="add-button" color="success"
                                                                                       size="small"
                                                                                       onClick={addDiversHandler}>
                                                                                <IonIcon slot="icon-only"
                                                                                         icon={addOutline}/>
                                                                            </IonButton>
                                                                        </IonCol>
                                                                    </React.Fragment>
                                                                ) :
                                                                (
                                                                    <IonCol>
                                                                        <IonText>
                                                                            <p id="sum">{splitOrder.generateBill().getTotalPrice()}€</p>
                                                                        </IonText>
                                                                    </IonCol>
                                                                )
                                                            }
                                                            <IonCol>
                                                                <IonButton id="add-button" color="tertiary" size="small"
                                                                           onClick={() => setStartClosingTable(true)}>
                                                                    <IonIcon slot="icon-only" icon={closeOutline}/>
                                                                </IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonText id="button-text-add">Split</IonText>
                                                            </IonCol>
                                                            {
                                                                !isSplitting
                                                                    ?
                                                                    <React.Fragment>
                                                                        <IonCol>
                                                                            <IonText id="button-text-add">Add
                                                                                Product</IonText>
                                                                        </IonCol>
                                                                        <IonCol>
                                                                            <IonText id="button-text-add">Add
                                                                                Divers</IonText>
                                                                        </IonCol>
                                                                    </React.Fragment>
                                                                    :
                                                                    <IonCol>

                                                                    </IonCol>
                                                            }
                                                            <IonCol>
                                                                <IonText id="button-text-add">Close</IonText>
                                                            </IonCol>
                                                        </IonRow>
                                                    </React.Fragment>
                                                }</React.Fragment>)
                                        }
                                        {startEditing && (<React.Fragment>
                                            <IonRow className="ion-margin-top">
                                                <IonCol>
                                                    <IonButton id="add-button" color="danger" size="small"
                                                               onClick={() => setOpenAlert(true)}>
                                                        <IonIcon slot="icon-only" icon={closeOutline}/>
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonText id="button-text-add">Delete Table</IonText>
                                                </IonCol>
                                            </IonRow>
                                        </React.Fragment>)}
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                {
                    order &&
                    <OrderList
                        order={order}
                        setOrder={setOrder}
                        selectedTable={selectedTable}
                        isSplitting={isSplitting}
                        setSplitBillTotal={setSplitBillTotal}
                        splitOrder={splitOrder}
                        setSplitOrder={setSplitOrder}
                        copiedOrder={copiedOrder}
                        setCopiedOrder={setCopiedOrder}
                    />
                }
                {
                    selectedTable.order.getClosedOrders().length > 0
                    &&
                    <ClosedOrderList order={selectedTable.order}/>
                }
                <AddOrderModal showModal={startAddingOrder} onCancel={cancelModalHandler} table={selectedTable}/>
                <AddDiversModal showModal={startAddingDivers} onCancel={cancelAddDiversModal} table={selectedTable}/>
                <CloseModal
                    show={startClosingTable}
                    table={selectedTable}
                    onCancel={cancelClosingTable}
                    order={isSplitting ? splitOrder : getDeepCopyOfProductsWithQuantity()}
                    isSplitting={isSplitting}
                    splitOrder={splitOrder}
                />
                <IonLoading
                    isOpen={showLoading}
                    message={'Please wait...'}
                />
                <IonAlert
                    isOpen={openAlert}
                    header={"Delete Table"}
                    subHeader={"Are you sure?"}
                    buttons={[
                        {
                            text: 'Cancel',
                            handler: () => {
                                setOpenAlert(false);
                            }
                        },
                        {
                            text: 'Delete',
                            handler: () => {
                                history.replace('/push');
                                deleteTableHandler()
                            }
                        }
                    ]}
                />
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={"Table has been deleted."}
                    position={"bottom"}
                    duration={1000}
                />
            </IonContent>}
            {
                !selectedTable
                &&
                <IonContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <h1>This table does not exist!</h1>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            }
        </IonPage>
    );
};

export default Table;