import React from 'react';
import firebase from 'firebase/app'
import {Bill, ClosedBill, ClosedBillForDatabase} from "./Bill";
import Order from "../components/Orders";

var uniqid = require('uniqid');

export class Product {
    id: string;
    name: string;
    price: number;
    category: "drinks" | "food";

    constructor(id: string, name: string, price: number, category: "drinks" | "food") {
        this.id = id;
        this.name = name;
        this.price = price;
        this.category = category;
    }

    public getId() {
        return this.id;
    }

    public getName() {
        return this.name;
    }

    public getPrice() {
        return this.price;
    }

    public getCategory() {
        return this.category;
    }

}

interface ProductsInterface {
    [productId: string]: Product;
}

export interface ProductsForDatabase {
    id: string,
    category: string,
    price: number,
    name: string
}


export class Products {
    products: ProductsInterface

    constructor(products: ProductsInterface) {
        this.products = products;
    }

    public getProducts(): ProductsInterface {
        if (this.hasProducts()) {
            return this.products;
        } else {
            throw new Error('There are no products')
        }
    }

    public getProductsSave(): ProductsInterface | null {
        if (this.hasProducts()) {
            return this.products;
        }
        return null;
    }

    public hasProduct(productId: string): boolean {
        return productId in this.products
    }

    public getProduct(productId: string): Product {
        if (!this.hasProduct(productId)) {
            throw new Error('Product ' + productId + ' does not exist')
        }

        return this.products[productId];
    }

    public getProductSave(productId: string): Product | null {
        if (!this.hasProduct(productId)) {
            return null;
        }

        return this.products[productId];
    }

    public deleteProductSave(productId: string): void {
        if (this.hasProduct(productId)) {
            delete this.products[productId]
            return;
        }
    }

    public searchProducts(query: string): Products {
        const formattedQuery = query.toLocaleLowerCase().trim();
        if (formattedQuery.length > 0) {
            const result = this.filterProducts(formattedQuery);
            return this.formatListToProducts(result);
        }
        return new Products(this.products);
    }

    public getProductsForDatabase(): { products: { [productId: string]: ProductsForDatabase } } {
        const finalObject: { products: { [productId: string]: ProductsForDatabase } } = {products: {}}
        if (this.hasProducts()) {
            Object.values(this.products).forEach(product => {
                finalObject.products[product.id] = {
                    ...product
                }
            })
        }
        return finalObject
    }

    public addProductSave(
        id: string,
        name: string,
        price: number,
        category: "drinks" | "food",
    ): Product | null {
        if (this.hasProduct(id)) {
            return null
        } else {
            const newProduct = new Product(id, name, price, category);
            this.products[id] = newProduct;
            return newProduct;
        }
    }

    private hasProducts(): boolean {
        return !!this.products;
    }

    private filterProducts(term: string): Product[] {
        return Object.values(this.products).filter(product => {
            return product.name.toLowerCase().includes(term);
        })
    }

    private formatListToProducts(productList: Product[]): Products {
        const formattedResult: ProductsInterface = {}
        productList.forEach(product => {
            formattedResult[product.id] = new Product(product.id, product.name, product.price, product.category);
        })
        return new Products(formattedResult);
    }

}

export class ProductWithQuantity extends Product {
    quantity: number;


    constructor(id: string, name: string, price: number, category: "drinks" | "food", quantity: number) {
        super(id, name, price, category);
        this.quantity = this.setQuantity(quantity);

    }


    public increaseQuantityByOne(): number {
        this.updateQuantity(1)
        return this.quantity;
    }

    public decreaseQuantityByOne(): number {
        this.updateQuantity(-1)
        return this.quantity;
    }

    public updateQuantity(quantity: number): number {
        if (this.quantity + quantity < 0) {
            this.quantity = 0
            return quantity;
        }
        this.quantity = this.quantity + quantity;
        return quantity
    }

    public getTotalPrice(): string {
        const totalPrice = this.price * this.quantity;
        return (Math.round(totalPrice * 100) / 100).toFixed(2);
    }

    private setQuantity(quantity: number = 0): number {
        if (quantity >= 0) {
            this.quantity = quantity
            return quantity
        } else {
            this.quantity = 0
            return this.quantity
        }
    }


}

export interface ProductsForWithQuantity {
    [productId: string]: ProductWithQuantity
}

export interface ProductsForDatabaseWithQuantity {
    id: string,
    quantity: number,
    category: string,
    price: number,
    name: string
}

export interface ClosedProductsWithQuantityForDatabase {
    bill: ClosedBillForDatabase | null,
    isClosed: boolean,
    id: string,
    products: { [productId: string]: ProductsForDatabaseWithQuantity }
}

export class ProductsWithQuantity {
    // if order is not closed, generating a new bill is possible with a function
    // if order is closed the attribute returns an instance of the bill class with the bill as one attribute and the tip as another

    id: string;
    isClosed: boolean;
    bill: ClosedBill | null;
    private readonly products: ProductsForWithQuantity;

    constructor(products: ProductsForWithQuantity) {
        this.id = uniqid();
        this.products = products;
        this.isClosed = false;
        this.bill = null;
    }

    public getProducts(): ProductsForWithQuantity {
        if (this.hasProducts()) {
            return this.products;
        } else {
            throw new Error('This table doesn\'t have any products')
        }
    }

    public getProductsSave(): ProductsForWithQuantity | null {
        if (this.hasProducts()) {
            return this.products;
        }
        return null;
    }

    public getProductsFiltered(field: 'category', filterTerm: string): ProductsForWithQuantity {
        if (field === 'category') {
            if (this.hasProducts()) {
                const filteredArray = Object.values(this.products).filter(product => {
                    return product.category === filterTerm
                })
                const newProducts: ProductsWithQuantity = new ProductsWithQuantity({})
                filteredArray.forEach(product => {
                    newProducts.addProductSave(product.id, product.name, product.price, product.category, product.quantity)
                })
                return newProducts.products
            }
        }
        return {}
    }

    public hasProduct(productId: string): boolean {
        return productId in this.products
    }

    public getProduct(productId: string): ProductWithQuantity {
        if (!this.hasProduct(productId)) {
            throw new Error('Product ' + productId + ' does not exist')
        }

        return this.products[productId];
    }

    public getProductSave(productId: string): ProductWithQuantity | null {
        if (!this.hasProduct(productId)) {
            return null;
        }

        return this.products[productId];
    }

    public deleteProductSave(productId: string): void {
        if (this.hasProduct(productId)) {
            delete this.products[productId]
            return;
        }
    }

    public getProductsForDatabase(): { products: { [productId: string]: ProductsForDatabaseWithQuantity } } {
        const finalObject: { products: { [productId: string]: ProductsForDatabaseWithQuantity } } = {products: {}}
        if (this.hasProducts()) {
            Object.values(this.products).forEach(product => {
                finalObject.products[product.id] = {
                    ...product
                }
            })
        }
        return finalObject
    }

    public addProductSave(
        id: string,
        name: string,
        price: number,
        category: "drinks" | "food",
        quantity: number
    ): ProductWithQuantity {
        if (this.hasProduct(id)) {
            this.products[id].updateQuantity(quantity);
            return this.products[id];
        } else {
            const newProduct = new ProductWithQuantity(id, name, price, category, quantity);
            this.products[id] = newProduct;
            return newProduct;
        }
    }

    public addProductDivers(price: number, description: string, category: 'food' | 'drinks'): ProductWithQuantity {
        const newProductId = uniqid() + '-divers';
        const newProduct = new ProductWithQuantity(newProductId, description, price, category, 1);
        this.products[newProduct.id] = newProduct;
        return newProduct;
    }

    public generateBill(): Bill {
        return new Bill(this.products);
    }

    public getClosedBillSave(): ClosedBill | null {
        if (this.bill instanceof ClosedBill) {
            return this.bill;
        }
        return null;
    }

    public closeProductsWithQuantity(paidAmount: number, paymentType: 'cash' | 'card', tableId: string): void {
        this.isClosed = true;
        this.bill = new ClosedBill(this.products, paidAmount, paymentType, tableId);
        this.bill.saveClosedBillToDatabase();
    }

    public getOrderForDatabase(): ClosedProductsWithQuantityForDatabase {
        const objectForDatabase = {
            bill: null,
            isClosed: this.isClosed,
            products: this.getProductsForDatabase().products,
            id: this.id,
        };
        if (this.bill) {
            // @ts-ignore
            objectForDatabase.bill = {
                id: this.bill.id,
                products: this.bill.getProductsForDatabase(),
                paidAmount: this.bill.paidAmount,
                tip: Number(this.bill.tip),
                paymentType: this.bill.paymentType,
                closedAt: this.bill.closedAt,
                tableId: this.bill.tableId,
            }
        }
        return objectForDatabase;

    }

    public decreaseQuantityFromProduct(product: ProductWithQuantity): null | number {
        if (!this.hasProduct(product.id)) {
            return null;
        }

        const productFromOrder = this.getProduct(product.id);
        productFromOrder.updateQuantity(-product.quantity)

        if (productFromOrder.quantity <= 0) {
            this.deleteProductSave(productFromOrder.id)
            return 0;
        }

        return product.quantity;

    }

    public decreaseQuantityByOneFromProduct(product: ProductWithQuantity): null | number {
        if (!this.hasProduct(product.id)) {
            return null;
        }

        const productFromOrder = this.getProduct(product.id);
        productFromOrder.decreaseQuantityByOne()

        if (productFromOrder.quantity === 0) {
            this.deleteProductSave(productFromOrder.id)
            return 0;
        }

        return product.quantity;

    }

    public decreaseQuantityByOneFromProductKeepZero(product: ProductWithQuantity): null | number {
        if (!this.hasProduct(product.id)) {
            return null;
        }

        const productFromOrder = this.getProduct(product.id);
        productFromOrder.decreaseQuantityByOne()

        return product.quantity;

    }

    public increaseQuantityByOneFromProduct(product: ProductWithQuantity): number {
        this.addProductSave(product.id, product.name, product.price, product.category, 1);
        return 1
    }

    public hasProducts(): boolean {
        return !!this.products;
    }

}


export interface ProductInOrder {
    id: string;
    name: string;
    price: number;
    category: string | null;
    quantity: number;
}

export interface Table {
    id: string;
    tableNumber: number;
    order: Order;
    createdAt: Date;
    updatedAt: Date;
    achieved: boolean;
    tip?: number;
    paymentType?: "cash" | "card";
}

interface TablesContext {
    user: firebase.User | null,
    tables: Table[],
    products: Products | null,
    bills: ClosedBill[] | null,
    login: (email: string, password: string) => firebase.auth.UserCredential | any,
    register: (email: string, password: string, name?: string) => {} | null,
    logout: () => void;
    updateTableNumber: (tableId: string, tableNumber: number) => Table | void;
    addProduct: (name: string, price: number, category: 'food' | 'drinks') => any;
    getProducts: () => any;
}

// @ts-ignore
const FirebaseContext = React.createContext<TablesContext>({
    user: null,
    tables: [],
    products: new Products({}),
    bills: [],
    login: () => null,
    register: () => null,
    logout: () => '',
    updateTableNumber: () => {
    },
    addProduct: () => '',
    getProducts: () => '',
})

export default FirebaseContext;