import React, {useContext, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import TableModal from "../../components/EditTablesModal";
import {addOutline} from "ionicons/icons";
import FirebaseContext from "../../data/tables-context-firebase";
import TableList from "./TableList";

const TablesOpen: React.FC = () => {

    const firebaseCtx = useContext(FirebaseContext);
    const tables = firebaseCtx.tables.filter(table => {
        return !table.achieved;
    })

    const [showModal, setShowModal] = useState(false);

    const startEditingHandler = () => {
        setShowModal(true);
    };

    const cancelHandler = () => {
        setShowModal(false);
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Tables</IonTitle>
                    <IonButtons slot="end">
                        <IonButton fill="clear" onClick={startEditingHandler}>
                            <IonIcon slot="icon-only" icon={addOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <TableList tables={tables} tablesAreOpen={true}/>
                    </IonRow>
                </IonGrid>
                <TableModal show={showModal} onCancel={cancelHandler}/>
            </IonContent>
        </IonPage>
    );
};

export default TablesOpen;