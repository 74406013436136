import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

  let firebaseConfig = {
    apiKey: "AIzaSyCR26LHRf68RLAwzPQ85dYPItayWXw9lBA",
    authDomain: "servify-d3313.firebaseapp.com",
    databaseURL: "https://servify-d3313.firebaseio.com",
    projectId: "servify-d3313",
    storageBucket: "servify-d3313.appspot.com",
    messagingSenderId: "1067511981063",
    appId: "1:1067511981063:web:a142ddd5fac14b7d4fb6a2"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  

  export default firebase;