import React, {useEffect, useState} from 'react';

import FirebaseContext, {
    Products,
    ProductsForDatabaseWithQuantity, ProductsForWithQuantity,
    ProductsWithQuantity,
    ProductWithQuantity,
    Table
} from "./tables-context-firebase";
import firebase from "../firebase_setup";

import Firestore from "./Database";
import {ClosedBill, ClosedBillForDatabase} from "./Bill";
import Order from "../components/Orders";

export const uid = 'yWxYBQuiZTeOClw23XKRw29aEwm1';


const FirebaseContextProvider: React.FC = props => {

    const db = new Firestore();

    const [user, setUser] = useState<firebase.User | null>(null);
    const [tables, setTables] = useState<Table[]>([]);
    const [products, setProducts] = useState<Products | null>(null);
    const [bills, setBills] = useState<ClosedBill [] | null>(null);

    const parseProductsWithQuantityFromDatabase = (products: { [productId: string]: ProductsForDatabaseWithQuantity }): ProductsForWithQuantity  => {
        const parsedProducts: { [productId: string]: ProductWithQuantity } = {};
        Object.values(products).forEach((product: any) => {
            parsedProducts[product.id] = new ProductWithQuantity(
                product.id,
                product.name,
                product.price,
                product.category,
                product.quantity
            )
        })
        return parsedProducts;
    }

    const parseBillFromDatabase = (bill: null | ClosedBillForDatabase): null | ClosedBill => {
        if (!bill) {
            return null;
        }
        const databaseBill = bill;
        const parsedProducts = parseProductsWithQuantityFromDatabase(bill.products)
        const newClosedBill = new ClosedBill(parsedProducts, databaseBill.paidAmount, databaseBill.paymentType, databaseBill.tableId);
        newClosedBill.id = databaseBill.id;
        // @ts-ignore
        newClosedBill.closedAt = databaseBill.closedAt.toDate();
        return newClosedBill;
    }

    // Auth Operations
    const register = async (email: string, password: string, name?: string) => {
        const newUser = await firebase.auth().createUserWithEmailAndPassword(
            email,
            password
        );
        return newUser.user?.updateProfile({
            displayName: name
        });
    };

    const login = async (email: string, password: string) => {
        return firebase.auth().signInWithEmailAndPassword(email, password)
    };

    const logout = async () => {
        await firebase.auth().signOut()
        window.location.reload();
    };

    const updateTableNumber = (tableId: string, tableNumber: number) => {
        const tableRef = firebase.firestore().collection('tables').doc(tableId);
        tableRef.get().then(doc => {
            if (doc.exists) {
                tableRef.update({tableNumber: tableNumber})
            }
        })
    };

    // Product Operations
    const addProduct = async (name: string, price: number, category: 'food' | 'drinks') => {
        const product = await db.addProduct(name, price, category);
        return product.id;
    };

    const getProducts = async () => {
        setProducts(await db.getProducts());
    };

    // TablesOpen Mount Operations and Subscriptions
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setUser(user)
            } else {
                setUser(null)
            }
        });
        return () => unsubscribe()
    }, [])


    useEffect(() => {
        getTables()
    }, []);

    const getTables = () => {
        firebase.firestore().collection('tables')
            .orderBy('updatedAt', "desc")
            .onSnapshot(handleSnapshot)
    }


    const handleSnapshot = (snapshot: any) => {
        const newTables: Table[] = []
        snapshot.docs.forEach((doc: {
            id: any;
            data: () => {
                paymentType: "cash" | "card";
                tip: number;
                (): any;
                new(): any; tableNumber: any;
                order: any; createdAt: {
                    (): any;
                    new(): any; toDate: {
                        (): any;
                        new(): any;
                    };
                }; updatedAt: {
                    (): any;
                    new(): any; toDate: {
                        (): any;
                        new(): any;
                    };
                }; achieved: any;
            };
        }) => {
            const orders = doc.data().order.orders

            // transform each order in orders to ProductsWithQuantity

            const parsedOrders = Object.values(orders).map(order => {
                // @ts-ignore
                const parsedProducts = parseProductsWithQuantityFromDatabase(order.products)
                const productsWithQuantity = new ProductsWithQuantity(parsedProducts);
                // @ts-ignore
                productsWithQuantity.id = order.id;
                // @ts-ignore
                productsWithQuantity.isClosed = order.isClosed;
                // @ts-ignore
                productsWithQuantity.bill = parseBillFromDatabase(order.bill);
                return productsWithQuantity;
            })

            const initialOrder = parsedOrders.filter(order => order.id.endsWith('first'))[0];
            const ordersWithoutInitialOrder = parsedOrders.filter(order => !order.id.endsWith('first'));

            let newOrder = new Order();

            if (initialOrder){
                newOrder = new Order(initialOrder.id, initialOrder);
            }

            ordersWithoutInitialOrder.forEach(order => {
                newOrder.addOrderFromDatabase(order);
            })

            const newTable = {
                id: doc.id,
                tableNumber: doc.data().tableNumber,
                order: newOrder,
                createdAt: doc.data().createdAt.toDate(),
                updatedAt: doc.data().updatedAt.toDate(),
                achieved: doc.data().achieved,
                tip: doc.data().tip,
                paymentType: doc.data().paymentType,
            }
            newTables.push(newTable)
        })
        setTables(newTables)
    }

    const getBills = async () => {
        const bills = db.getClosedBills();
        setBills(await bills);
    }


    // Products Mount Operations and Subscriptions
    useEffect(() => {
        getProducts()
    }, []);

    useEffect(() => {
        getBills();
    }, [tables]);

    return (
        <FirebaseContext.Provider value={{
            user,
            tables,
            products,
            bills,
            login,
            register,
            logout,
            updateTableNumber,
            addProduct,
            getProducts,
        }}>
            {props.children}
        </FirebaseContext.Provider>
    )
}

export default FirebaseContextProvider;