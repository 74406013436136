import React from 'react';
import {IonAlert} from "@ionic/react";
import {Table} from "../../data/tables-context-firebase";
import {Values} from "../../components/auth/useFormValidation";

const AlertTableDoesExist: React.FC<{
    openAlert: boolean;
    setOpenAlert(boolean: boolean): void;
    tables: Table[];
    history: { push(path: string): void; };
    onCancel(): void;
    values: Values;
}> = props => {
    return (
        <IonAlert
            isOpen={props.openAlert}
            header={'Table already exists.'}
            subHeader={'Do you want to go to the table?'}
            buttons={[
                {
                    text: 'Cancel',
                    handler: () => {
                        props.setOpenAlert(false);
                    }
                },
                {
                    text: 'To Table',
                    handler: () => {
                        const table = props.tables.filter(table => !table.achieved)
                            .find(table => table.tableNumber === props.values.tableNumber!)
                        const tableId = table!.id
                        const path = '/tables/' + tableId
                        props.history.push(path);
                        props.setOpenAlert(false);
                        props.onCancel();
                    }
                }
            ]}
        />
    )
}

export default AlertTableDoesExist;