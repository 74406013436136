import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel, IonLoading,
    IonModal,
    IonRow, IonSelect, IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {useHistory} from "react-router-dom";

import './EditTablesModel.css';
import FirebaseContext from "../data/tables-context-firebase";
import useFormValidation from "./auth/useFormValidation";
import validateCreateProduct from "./auth/validateCreateProduct";
import {uid} from "../data/FirebaseContextProvider";

const INITIAL_STATE = {
    name: "",
    price: 0,
}


const AddProductModal: React.FC<{ show: boolean; onCancel: () => void }> = props => {
    const {addProduct, getProducts, user} = useContext(FirebaseContext);
    const [loading, setLoading] = useState<boolean>(false);
    const {handleSubmit, handleChange, values, errors, setValues} = useFormValidation(INITIAL_STATE, validateCreateProduct, handleCreateProduct)

    const [category, setCategory] = useState<"food" | "drinks">("food");
     const firebaseCtx = useContext(FirebaseContext);

    async function handleCreateProduct() {
        setLoading(true);
        if (user) {
            try {
                await addProduct(values.name!, values.price!, values.category!);
                await getProducts();
                setLoading(false);
                props.onCancel();
            }catch (e) {
                console.error(e)
            }


        }
    }

    useEffect(() => {
        setValues(prevValues => ({
            ...prevValues,
            "category": category
        }))
    }, [category])


    const handleCancel = () => {
        props.onCancel();
    };
    return (
        <IonModal
            isOpen={props.show}
        >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Add Product
                    </IonTitle>
                    <IonButtons slot="start">
                        <IonButton onClick={handleCancel}>
                            <IonLabel>Cancel</IonLabel>
                        </IonButton>
                    </IonButtons>
                    {firebaseCtx.user?.uid === uid && <IonButtons slot="end">
                        <IonButton onClick={handleSubmit}>
                            <IonLabel>Done</IonLabel>
                        </IonButton>
                    </IonButtons>}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center ion-margin-top">
                            <IonItem>
                                <IonLabel>Category: </IonLabel>
                                <IonSelect value={category} interface="popover" onIonChange={e => setCategory(e.detail.value)}>
                                    <IonSelectOption value="food">Food</IonSelectOption>
                                    <IonSelectOption value="drinks">Drinks</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            {errors.category && <IonText>{errors.category}</IonText>}
                            <IonItem>
                                <IonLabel>Name: </IonLabel>
                                <IonInput
                                    type="text"
                                    name="name"
                                    onIonChange={handleChange}
                                />
                            </IonItem>
                            {errors.name && <IonText>{errors.name}</IonText>}
                            <IonItem>
                                <IonLabel>Price: </IonLabel>
                                <IonInput
                                    type="number"
                                    name="price"
                                    onIonChange={handleChange}
                                />
                            </IonItem>
                            {errors.price && <IonText>{errors.price}</IonText>}
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonLoading
                    isOpen={loading}
                    message={"Creating product..."}
                />
            </IonContent>
        </IonModal>
    );
};

export default AddProductModal;