import {
    ProductsForDatabase,
    ProductsForDatabaseWithQuantity,
    ProductsForWithQuantity,
    ProductWithQuantity
} from "./tables-context-firebase";
import Firestore from "./Database";
var uniqid = require('uniqid');

export class Bill {
    protected products: ProductsForWithQuantity;

    constructor(products: ProductsForWithQuantity) {
        this.products = products;
    }

    public getTotalPrice(): string {
        const price = Object.values(this.products).reduce((accumulator, current) => {
            return accumulator + current.price * current.quantity;
        }, 0);
        const rounded = Math.round(price * 100) / 100;
        return rounded.toFixed(2);
    }

    public getTotalPriceWithTip(): string {
        const totalPrice = parseFloat(this.getTotalPrice());
        const tip = totalPrice * 0.10;
        const finalPrice = totalPrice + tip;

        return (Math.round(finalPrice * 100) / 100).toFixed(2);
    }

    public getFood(): ProductWithQuantity[]  {
        return Object.values(this.products).filter(product => product.category === 'food');
    }

    public getDrinks(): ProductWithQuantity[] {
        return Object.values(this.products).filter(product => product.category === 'drinks');
    }

    public getTotalQuantity(): number {
        return Object.values(this.products).reduce((accumulator, current) => {
            return accumulator = accumulator + current.quantity;
        },0)
    }

    public getProductsSave(): ProductWithQuantity[] | null {
        if (this.products) {
            return Object.values(this.products);
        }
        return null;
    }


}

export interface ClosedBillForDatabase {
    id: string,
    products: {[productId: string]: ProductsForDatabaseWithQuantity},
    paidAmount: number,
    tip: number,
    paymentType: 'cash' | 'card',
    closedAt: Date,
    tableId: string,
}

export class ClosedBill extends Bill {
    id: string;
    paidAmount: number;
    tip: string;
    paymentType: 'cash' | 'card';
    closedAt: Date;
    tableId: string;

    constructor (products: ProductsForWithQuantity, paidAmount: number, paymentType: 'cash' | 'card', tableId: string){
        super(products);
        this.paidAmount = paidAmount;
        this.tip = this.getTotalTip();
        this.paymentType = paymentType;
        this.closedAt = new Date();
        // generate unique id on the client side
        this.id = uniqid();
        this.tableId = tableId;
    }

    private getTotalTip(): string {
        if (this.paidAmount) {
            const tip =  this.paidAmount - Number(this.getTotalPrice());
            const rounded = Math.round(tip * 100) / 100;
            return rounded.toFixed(2);
        }
        const tip = 0;
        return tip.toFixed(2);
    };

    public getProductsForDatabase(): {[productId: string]: ProductsForDatabaseWithQuantity} {
         const finalObject: { [productId: string]:  ProductsForDatabaseWithQuantity } = {};
        if (this.products) {
            Object.values(this.products).forEach(product => {
                finalObject[product.id] = {
                    ...product
                }
            })
        }
        return finalObject;
    }

    public saveClosedBillToDatabase(): void  {
        const db = new Firestore();
        const products = this.getProductsForDatabase();
        db.addClosedBill(products, this.paidAmount, this.tip, this.id, this.closedAt, this.paymentType, this.tableId);
    }
}


