import React from 'react';
import {
    IonButton,
    IonChip,
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonText
} from "@ionic/react";
import {Products} from "../data/tables-context-firebase";
import {trashOutline} from "ionicons/icons";

const ProductList: React.FC<{ products: Products; searchedText: string; setDeleteItem: (itemId: string) => void }> = props => {
    return (
        <IonList inset={true} lines="inset">
            {Object.values(props.products.products).map(product => {
                return (
                    <IonItemSliding key={product.getId()}>
                        <IonItem>
                            <IonChip slot="start">{product.getCategory()}</IonChip>
                            <IonText slot="end" color="success">{product.getPrice()}€</IonText>
                            <IonLabel>{product.name}</IonLabel>
                        </IonItem>
                        <IonItemOptions>
                            <IonItemOption color="danger">
                                <IonButton color="danger" onClick={() => {
                                    props.setDeleteItem(product.id)
                                }}
                                >
                                    <IonIcon icon={trashOutline}/>
                                </IonButton>
                            </IonItemOption>
                        </IonItemOptions>
                    </IonItemSliding>
                )
            })}
        </IonList>
    )
};

export default ProductList;