import React from 'react';

export interface ValidationErrors {
    name?: string,
    price?: string,
    category?: string,
    tableNumber?: string,
    email?: string,
    password?: string,
}


export interface Values {
    category?: 'food' | 'drinks',
    name?: string,
    email?: string,
    password?: string,
    tableNumber?: number,
    price?: number,
}

const useFormValidation = (initialState: Values, validate: (values: Values) => ValidationErrors, userAuthentication: any) => {
    const [values, setValues] = React.useState<Values>(initialState);
    const [errors, setErrors] = React.useState<ValidationErrors>({});
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (isSubmitting) {
            const noErrors = Object.keys(errors).length === 0;
            if (noErrors) {
                userAuthentication()
                setIsSubmitting(false);
            } else {
                setIsSubmitting(false);
            }
        }
    }, [errors])

    const handleChange = (event: any) => {
        setValues(prevValues => ({
            ...prevValues,
            [event.target.name]: event.target.value
        }))
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const validationErrors = validate(values);
        setErrors(validationErrors);
        setIsSubmitting(true);
    }

    const handleBlur = () => {
        const validationErrors = validate(values)
        setErrors(validationErrors);
    }

    return {handleChange, handleBlur, handleSubmit, values, errors, isSubmitting, setValues}
};

export default useFormValidation;