import React, {useContext, useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonLoading,
    IonModal,
    IonSearchbar,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import FirebaseContext, {Products, ProductsWithQuantity} from "../data/tables-context-firebase";
import AddOrderProductList from "./AddOrderProductList";
import Firestore from "../data/Database";

interface orders {
    id: string,
    quantity: number,
}

const AddOrderModal: React.FC<{ showModal: boolean; onCancel: () => void; table: any; }> = props => {
    const db = new Firestore();
    const firebaseCtx = useContext(FirebaseContext)
    const [order, setOrder] = useState<ProductsWithQuantity>(new ProductsWithQuantity({}));
    const [searchText, setSearchText] = useState('');
    const [productOrderArray, setProductOrderArray] = useState();
    const [loading, setLoading] = useState<boolean>(false);

     useEffect(() => {
        generateProductOrderArray();
    }, [])

    const generateProductOrderArray = () => {
        const productOrderArray = Object.values(firebaseCtx.products!.searchProducts(searchText).products).map(product => {
            if (order.hasProduct(product.id)) {
                return {
                    ...order.getProductSave(product.id)
                }
            }
            return {
                ...product,
                quantity: 0
            }
        })
        setProductOrderArray(productOrderArray)
    }

    const addOrder = ( productId: string, increase?: boolean, decrease?: boolean) => {
        if (order.hasProduct(productId)) {
            const product = order.getProduct(productId)
            if (increase){
                product.increaseQuantityByOne()
            } else {
                product.decreaseQuantityByOne()
            }
        } else if (increase){
            const product = firebaseCtx.products!.getProduct(productId)
            order.addProductSave(product.id, product.name, product.price, product.category, 1)
        }
        setOrder(prevState => {
         return new ProductsWithQuantity(prevState.getProducts())
        })
    }

    const submitHandler = async () => {
        Object.values(order.getProducts()).forEach(product => {
            if(product.quantity > 0){
                props.table
                .order.getFirstOpenOrder().addProductSave(product.id, product.name, product.price, product.category, product.quantity)
            }
        })
        await db.updateTable(props.table,props.table.order.parseOrderForDatabase())
        setSearchText('');
        setOrder(new ProductsWithQuantity({}));
        generateProductOrderArray();
        props.onCancel();
    };

    const cancelHandler = () => {
        setSearchText('');
        setOrder(new ProductsWithQuantity({}));
        generateProductOrderArray();
        props.onCancel();
    };

    useEffect(() => {
        generateProductOrderArray();
    }, [searchText, order])



    return (
        <IonModal
            isOpen={props.showModal}
        >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Add Order</IonTitle>
                    <IonButtons slot="start">
                        <IonButton fill="clear" onClick={cancelHandler}>Cancel</IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton fill="clear" color={order.getProducts() ? "success" : "primary"}
                                   onClick={submitHandler}>Add</IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar
                    showCancelButton="focus"
                    inputMode="search"
                    value={searchText}
                    onIonChange={e => setSearchText(e.detail.value!)}
                    placeholder={"Find product..."}
                    onIonClear={generateProductOrderArray}
                    onIonCancel={generateProductOrderArray}
                    autocomplete={"off"}
                />
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {productOrderArray &&
                <AddOrderProductList products={productOrderArray} searchedText={searchText} changeOrderQuantity={addOrder}/>}
                <IonLoading
                    isOpen={loading}
                    message="Adding order..."
                />
            </IonContent>
        </IonModal>
    );
};
export default AddOrderModal;