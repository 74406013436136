import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {Table} from "../data/tables-context-firebase";
import useFormValidation from "./auth/useFormValidation";
import validateCreateDivers from "./auth/validateCreateDivers";
import Firestore from "../data/Database";

const INITIAL_STATE = {
    name: "",
    price: 0,
};

const AddDiversModal: React.FC<{ showModal: boolean; onCancel(): void; table: Table; }> = props => {
    const db = new Firestore();

    const [category, setCategory] = useState<'food' | 'drinks'>('food');

    const handleAddDivers = async () => {
        props.table.order.getFirstOpenOrder().addProductDivers(values.price!, values.name!, values.category!);
        const orderForDatabase = props.table.order.parseOrderForDatabase();
        await db.updateTable(props.table, orderForDatabase);
        props.onCancel();
    };

    const {handleSubmit, handleChange, values, errors, setValues} = useFormValidation(INITIAL_STATE, validateCreateDivers, handleAddDivers)

    useEffect(() => {
        setValues(prevState => (
            {
                ...prevState,
                'category': category,
            }))
    }, [category])

    return (
        <IonModal
            isOpen={props.showModal}
        >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Add Divers</IonTitle>
                    <IonButtons slot="start">
                        <IonButton fill="clear" onClick={props.onCancel}>Cancel</IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton fill="clear" color="success" onClick={handleSubmit}>Add</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center ion-margin-top">
                            <IonItem>
                                <IonLabel>Category: </IonLabel>
                                <IonSelect value={category} interface="popover"
                                           onIonChange={e => setCategory(e.detail.value)}>
                                    <IonSelectOption value="food">Food</IonSelectOption>
                                    <IonSelectOption value="drinks">Drinks</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            {errors.category && <IonText>{errors.category}</IonText>}
                            <IonItem>
                                <IonLabel>Name: </IonLabel>
                                <IonInput
                                    type="text"
                                    name="name"
                                    onIonChange={handleChange}
                                />
                            </IonItem>
                            {errors.name && <IonText>{errors.name}</IonText>}
                            <IonItem>
                                <IonLabel>Price: </IonLabel>
                                <IonInput
                                    type="number"
                                    name="price"
                                    onIonChange={handleChange}
                                />
                            </IonItem>
                            {errors.price && <IonText>{errors.price}</IonText>}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    )
};

export default AddDiversModal;