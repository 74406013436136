import React, {useContext} from 'react';
import {IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import FirebaseContext from "../../data/tables-context-firebase";
import TableList from "./TableList";

const TablesAchieve: React.FC = () => {

    const firebaseCtx = useContext(FirebaseContext);

    const tables = firebaseCtx.tables.filter(table => {
        return table.achieved;
    })


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Achieve</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <TableList tables={tables} tablesAreOpen={false}/>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TablesAchieve;