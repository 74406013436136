import React, {useContext, useEffect, useState} from 'react';
import {IonReactRouter} from "@ionic/react-router";
import {Route} from "react-router";
import {IonApp, IonRouterOutlet} from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import PrivateRoute from "./components/PrivateRoute";
import Login from "./sides/Auth/Login/Login";
import Tabs from "./components/tabs/Tabs";
import StartupLoading from "./sides/StartupLoading";


const App: React.FC = () => {
    const [isLogin, setIsLogin] = useState(false);
    const [startupLoading, setStartupLoading] = useState(false);

    useEffect(() => {
        const isLogin = !window.location.href.endsWith('/login');
        setIsLogin(isLogin)
    })

    useEffect(()=> {
        setStartupLoading(true);
        setTimeout(()=>{
            setStartupLoading(false)
        }, 2500)
    },[])

    // @ts-ignore
    return (<IonApp>
            {startupLoading && (<StartupLoading />)}
            {!startupLoading && (<IonReactRouter>
                <IonRouterOutlet>
                    <Route path={"/login"} component={Login} exaxt/>
                    <PrivateRoute path={"/"} component={Tabs}/>
                </IonRouterOutlet>
            </IonReactRouter>)}
        </IonApp>
    )
};

export default App;
