import React, {useEffect} from "react";
import {IonBadge, IonButton, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow, IonText} from "@ionic/react";
import {addOutline, removeOutline} from "ionicons/icons";
import {ProductsWithQuantity, ProductWithQuantity, Table} from "../data/tables-context-firebase";
import Firestore from "../data/Database";

const OrderList: React.FC<{
    order: ProductsWithQuantity;
    setOrder: (order: ProductsWithQuantity) => void;
    selectedTable: Table;
    isSplitting: boolean;
    setSplitBillTotal: (totalPrice: number) => void;
    splitOrder: ProductsWithQuantity;
    setSplitOrder: (productsWithQuantity: ProductsWithQuantity) => void;
    copiedOrder: ProductsWithQuantity;
    setCopiedOrder: (productsWithQuantity: ProductsWithQuantity) => void;
}> = props => {
    const db = new Firestore()

    const getDeepCopyOfProductsWithQuantity = () => {
        const newProductsWithQuantity = new ProductsWithQuantity({});
        const products = props.order.getProducts();
        Object.values(products).forEach(product => {
            newProductsWithQuantity.addProductSave(product.id, product.name, product.price, product.category, product.quantity);
        })
        return newProductsWithQuantity;
    }

    const rerender = () => {
        const newOrder = new ProductsWithQuantity(props.splitOrder.getProducts());
        props.setSplitOrder(newOrder);
    }

    const addOneHandler = async (productId: string) => {
        const product = props.selectedTable!.order.getFirstOpenOrder().getProductSave(productId);
        product?.increaseQuantityByOne();
        if (product?.quantity === 0) {
            props.selectedTable!.order.getFirstOpenOrder().deleteProductSave(productId);
        }
        await db.updateTable(props.selectedTable!, props.selectedTable!.order.parseOrderForDatabase());
    };

    const subtractOneHandler = async (productId: string) => {
        const product = props.selectedTable!.order.getFirstOpenOrder().getProductSave(productId);
        product?.decreaseQuantityByOne();
        if (product?.quantity === 0) {
            props.selectedTable!.order.getFirstOpenOrder().deleteProductSave(productId);
        }
        await db.updateTable(props.selectedTable!, props.selectedTable!.order.parseOrderForDatabase());
    };

    const subtractOneFromSplitOrderHandler = (product: ProductWithQuantity) => {
        props.splitOrder.decreaseQuantityByOneFromProduct(product);
        props.copiedOrder.increaseQuantityByOneFromProduct(product);
        rerender();
    }

    const addOneToSplitOrderHandler = (product: ProductWithQuantity) => {
        props.splitOrder.increaseQuantityByOneFromProduct(product);
        props.copiedOrder.decreaseQuantityByOneFromProductKeepZero(product);
        rerender();
    }

    useEffect(() => {
        props.setOrder(props.selectedTable?.order.getFirstOpenOrder())
    }, [addOneHandler, subtractOneHandler])

    useEffect(() => {
        props.setCopiedOrder(getDeepCopyOfProductsWithQuantity());
    }, [])

    useEffect(() => {
        props.setCopiedOrder(getDeepCopyOfProductsWithQuantity());
    }, [props.isSplitting])


    if (!props.isSplitting) {
        return (
            <React.Fragment>
                {Object.values(props.order.getProducts()).length > 0 &&
                <IonRow>
                    <IonCol>
                        <IonText><h1 className="ion-margin-start">Orders</h1></IonText>
                        {props.selectedTable.order.getFirstOpenOrder().getProductsSave() && (
                            <IonList>
                                {props.selectedTable.order.getFirstOpenOrder().getProductsSave() && Object.values(props.selectedTable.order.getFirstOpenOrder().getProducts()).map((product) => {
                                    return (
                                        <IonItem lines="full" key={product.id}>
                                            <IonBadge className="ion-margin-end"
                                                      color="medium">{product.quantity}x</IonBadge>
                                            <IonLabel>{product.name}
                                                {
                                                    product.id.endsWith('-divers')
                                                    &&
                                                    <IonText
                                                        color={'medium'}> @{product.price}€
                                                    </IonText>
                                                }
                                            </IonLabel>
                                            {!props.selectedTable.achieved && (
                                                <React.Fragment>
                                                    <IonButton fill="clear"
                                                               onClick={() => subtractOneHandler(product.id)}><IonIcon
                                                        slot="icon-only" icon={removeOutline}
                                                        color="secondary"/></IonButton>
                                                    <IonButton fill="clear"
                                                               onClick={() => addOneHandler(product.id)}><IonIcon
                                                        slot="icon-only" icon={addOutline}
                                                        color="secondary"/></IonButton>
                                                </React.Fragment>
                                            )}
                                        </IonItem>
                                    )
                                })
                                }
                            </IonList>
                        )}
                    </IonCol>
                </IonRow>
                }
            </React.Fragment>
        )
    }
    return (
        <IonRow>
            <IonCol>
                <IonText><h1 className="ion-margin-start">Split Order</h1></IonText>
                <IonList>
                    {props.copiedOrder.getProductsSave() && Object.values(props.copiedOrder.getProducts()).map((product) => {
                        return (
                            <IonItem lines="full" key={product.id}>
                                <IonBadge className="ion-margin-end"
                                          color="medium">{product.quantity}x
                                </IonBadge>
                                <IonBadge className="ion-margin-end"
                                          color="success">{props.splitOrder.hasProduct(product.id) ? props.splitOrder.getProductSave(product.id)?.quantity : 0}x
                                </IonBadge>
                                <IonLabel>{product.name}
                                                {
                                                    product.id.endsWith('-divers')
                                                    &&
                                                    <IonText
                                                        color={'medium'}> @{product.price}€
                                                    </IonText>
                                                }
                                            </IonLabel>
                                {!props.selectedTable.achieved && (
                                    <React.Fragment>
                                        <IonButton
                                            fill="clear"
                                            onClick={() => subtractOneFromSplitOrderHandler(product)}
                                            disabled={!props.splitOrder.hasProduct(product.id)}
                                        >
                                            <IonIcon
                                                slot="icon-only" icon={removeOutline}
                                                color="secondary"
                                            />
                                        </IonButton>
                                        <IonButton
                                            fill="clear"
                                            onClick={() => addOneToSplitOrderHandler(product)}
                                            disabled={!product.quantity}
                                        >
                                            <IonIcon
                                                slot="icon-only" icon={addOutline}
                                                color="secondary"
                                            />
                                        </IonButton>
                                    </React.Fragment>
                                )}
                            </IonItem>
                        )
                    })
                    }
                </IonList>
            </IonCol>
        </IonRow>
    )
}

export default OrderList;